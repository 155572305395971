import axios from 'axios';

class AuthApiService {
  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });
  }

    async login(data) {
      return this.api.post('/login', data).then(res => res.data);
    }
}

export const authApiService = new AuthApiService();

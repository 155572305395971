import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  theme,
  Center,
  Container,
  Text,
  Textarea,
  Button,
  Select,
  Spacer,
  Grid,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Spinner, Link, Alert, AlertIcon,
} from '@chakra-ui/react';
import { apiService } from './ExternalApiService';
import { authApiService } from './AuthApiService';
import { useToast } from '@chakra-ui/react';

function App() {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [templateContent, setTemplateContent] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [csvFile, setCsvFile] = useState('');
  const [selectedDataId, setSelectedDataId] = useState('');
  const [currentDataIndex, setCurrentDataIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [previewData, setPreviewData] = useState("");

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoginOpen, setIsLoginOpen] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [usages, setUsages] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      const userLoggedIn = localStorage.getItem('isLoggedIn') === 'true'; // Check local storage
      const bearerToken = localStorage.getItem('token');
      apiService.setBearerToken(bearerToken);
      setIsLoggedIn(userLoggedIn);
      setIsLoading(false);
      if (!userLoggedIn) {
        setIsLoginOpen(true);
      }
    }, 1000);
  }, []);

  const onClose = () => setIsOpen(false);

  const toast = useToast();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setCsvFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = function(e) {
        const text = e.target.result;

        if (!doesCSVHavePhoneNumber(text)) {
          showToast('error', 'CSV does not have a phone_number column.', 'Please upload a CSV with a phone_number column.');
          return;
        }

        parseCSVtoJSON(text);
      };
      reader.readAsText(file);
    }
  };

  const doesCSVHavePhoneNumber = (csv) => {
    const lines = csv.split('\n');
    const headers = lines[0].split(',').map(header => header.trim().replace(/\r$/, ''));
    return headers.includes('phone_number');
  }

  const parseCSVtoJSON = (csv) => {
    const lines = csv.split('\n');
    const result = [];
    const headers = lines[0].split(',').map(header => header.trim().replace(/\r$/, ''));

    for (let i = 1; i < lines.length; i++) {
      if (!lines[i].trim()) continue; // Skip empty lines
      const obj = {};
      const currentLine = lines[i].split(',').map(value => value.trim().replace(/\r$/, ''));

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentLine[j];
      }
      result.push(obj);
    }

    setCsvData(result);
  };

  const showToast = (status, title, description = '') => {
    toast({
      title,
      description,
      status,
      duration: 5000,
      isClosable: true,
      position: "top",
    });
  };

  useEffect(() => {
    if (isLoggedIn) {
      apiService.fetchTemplates()
        .then(setTemplates)
        .catch(error => {
          if (error.response && error.response.status === 401) {
            // Log out the user if 401 Unauthorized is returned
            handleLogout();
          } else {
            console.error('Failed to fetch templates:', error);
          }
        });
      apiService.fetchUsages()
        .then(setUsages)
        .catch(error => {
          if (error.response && error.response.status === 401) {
            // Log out the user if 401 Unauthorized is returned
            handleLogout();
          } else {
            console.error('Failed to fetch templates:', error);
          }
        });
    }
  }, [isLoggedIn]);

  const handleTemplateChange = (event) => {
    const templateId = event.target.value;
    setSelectedTemplateId(templateId);
    if (templateId) {
      apiService.fetchTemplateById(templateId)
        .then(template => {
          setTemplateContent(template.content);
          setTemplateName(template.name);
        })
        .catch(error => showToast('error', `Failed to fetch template ${templateId}:`, error));
    } else {
      setTemplateContent('');
      setTemplateName('');
    }
  };

  const handleDataChange = (event) => {
    const dataId = event.target.value;
    setSelectedDataId(dataId);
    if (dataId) {
      setCurrentDataIndex(dataId);
    }
  };

  const handleSave = () => {
    if (selectedTemplateId && templateContent.trim() !== '') {
      setIsSaving(true);
      apiService.updateTemplate(selectedTemplateId, { content: templateContent, name: templateName })
        .then(() => {
          showToast('success', 'Template updated successfully.',
            `Template ${selectedTemplateId} has been updated.`);
          setTemplates(templates.map(template => {
            if (template.id === selectedTemplateId) {
              return { ...template, content: templateContent, name: templateName };
            }
            return template;
          }));
        })
        .catch(error => {
          showToast('error', `Failed to update template ${selectedTemplateId}:`, error);
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  const handleDelete = () => {
    if (selectedTemplateId) {
      setIsSaving(true);
      apiService.deleteTemplate(selectedTemplateId)
        .then(() => {
          showToast('success', 'Template deleted successfully.',
            `Template ${selectedTemplateId} has been deleted.`);
          setSelectedTemplateId('');
          setTemplateContent('');
          setTemplateName('');
          setTemplates(templates.filter(template => template.id !== selectedTemplateId));
        })
        .catch(error => {
          showToast('error', `Failed to delete template ${selectedTemplateId}:`, error);
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  }

  const handleCreate = () => {
    if (templateContent.trim() !== '' && templateName.trim() !== '') {
      setIsSaving(true);
      apiService.createTemplate({ content: templateContent, name: templateName })
        .then(template => {
          showToast('success', 'Template created successfully.',
            `Template ${template.id} has been created.`);
          setTemplates([...templates, template]);
          setSelectedTemplateId(template.id);
        })
        .catch(error => {
          showToast('error', 'Failed to create template:', error);
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  }

  const handlePreview = () => {
    if (selectedTemplateId && selectedDataId) {
      setIsSaving(true);
      apiService.previewTemplate(selectedTemplateId, csvData[currentDataIndex])
        .then(data => {
          setPreviewData(data);
          setIsOpen(true);
        })
        .catch(error => {
          showToast('error', `Failed to preview template ${selectedTemplateId}:`, error);
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  }

  const handleSendTextMessage = (templateId, dataId) => {
    if (templateId && dataId) {
      const data = JSON.stringify(csvData[dataId]);
      const messageData = {
        template_id: templateId,
        content_values: data,
        phone_number: csvData[currentDataIndex].phone_number,
        status: 'pending',
      };

      setIsSaving(true);

      apiService.createMessage(messageData)
        .then((data) => {
          const messageId = data.id;
          apiService.sendMessage(messageId)
            .then(() => {
              showToast('success', 'Message sent successfully.');
              apiService.fetchUsages()
                .then(setUsages)
                .catch(error => console.error('Failed to fetch usages:', error));
            })
            .catch(error => {
              showToast('error', 'Failed to send message:', JSON.stringify(error));
            });
        })
        .catch(error => {
          showToast('error', 'Failed to create message:', JSON.stringify(error));
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  }

  const handleBulkSendTextMessages = (templateId, csvFile) => {
    setIsSaving(true);
    apiService.sendBulkMessages(templateId, csvFile)
      .then(() => {
        showToast('success', 'Bulk messages successfully queued.');
        apiService.fetchUsages()
          .then(setUsages)
          .catch(error => console.error('Failed to fetch usages:', error));
        setIsSaving(false);

      })
      .catch(error => {
        showToast('error', 'Failed to queue bulk messages:', JSON.stringify(error));
        setIsSaving(false);
      });
  }

  const handleLogin = () => {

    if (!email || !password) {
      showToast('error', 'Failed to login',"Please enter your email and password.");
      return;
    }

    authApiService.login({ email, password })
      .then((data) => {
        localStorage.setItem('isLoggedIn', 'true');
        let token = data.authorization.token;
        localStorage.setItem('token', token);
        showToast('success', 'Logged in successfully.', 'You are now logged in.');
        apiService.setBearerToken(token);
        setIsLoginOpen(false);
        setIsLoggedIn(true);
      })
      .catch(() => showToast('error', 'Failed to login',"Please check your credentials and try again."));
  };

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setIsLoginOpen(true);
  };

  if (isLoading) {
    return (
      <ChakraProvider theme={theme}>
        <Center h="100vh">
          <Spinner size="xl" />
        </Center>
      </ChakraProvider>
    );
  }

  return (
    <ChakraProvider theme={theme}>
      <Modal isOpen={isLoginOpen} onClose={() => {}} isCentered>
        <ModalOverlay
          backdropFilter='blur(5px) hue-rotate(90deg)'
        />
        <ModalContent>
          <ModalHeader>Sign In</ModalHeader>
          <ModalBody>
            <Input placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <Spacer h="20px" />
            <Input placeholder="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            <Spacer h="20px" />
            <Button onClick={handleLogin}>Log in</Button>
            <Spacer h="20px" />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Preview</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{previewData}</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Center h="100vh" minH="700px" color="black">
        <Container h="700px" w="480px">
          <Grid templateColumns="1fr auto" gap={2}>
            <Text>Logged in as: {usages.email}</Text>
            <Link textColor="red" onClick={handleLogout}>Log out</Link>
          </Grid>
          <Spacer h="5px" />
          <Text>Usages: {usages.usage}</Text>
          <Spacer h="10px" />
          <Textarea
            placeholder="My name is {{name}} and I am {{age}} years old.
            I live in {{city}}.
            I work at {{company}}.
            I am a {{job}}.
            I have {{children}} children."
            size="lg"
            resize="none"
            h="200px"
            value={templateContent}
            onChange={(e) => setTemplateContent(e.target.value)}
          />
          <Spacer h="20px" />
          <Grid templateColumns="repeat(3, 1fr)" gap={1}>
            <Button onClick={handleCreate} isLoading={isSaving}>Create</Button>
            <Button onClick={handleSave} isLoading={isSaving}>Save</Button>
            <Button onClick={handleDelete} isLoading={isSaving}>Delete</Button>
          </Grid>
          <Spacer h="20px" />
          <Input
            placeholder="Template Name"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
          />
          <Spacer h="20px" />
          <Select placeholder="Select template" value={selectedTemplateId} onChange={handleTemplateChange}>
            {templates.map((template) => (
              <option key={template.id} value={template.id}>
                {template.name}
              </option>
            ))}
          </Select>
          <Spacer h="20px" />
          <Grid templateColumns="repeat(2, 1fr)" gap={2}>
            <Select placeholder="Select Data Row" value={selectedDataId} onChange={handleDataChange}>
              {csvData.map((data, index) => {
                const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
                const formattedData = Object.entries(data).map(([key, value]) =>
                  `${capitalizeFirstLetter(key)}: ${value}`
                ).join(', ');
                return (
                  <option key={index} value={index}>
                    {formattedData}
                  </option>
                );
              })}
            </Select>

            <Button isDisabled={selectedDataId === ''} onClick={handlePreview}>Preview</Button>
          </Grid>
          <Spacer h="20px" />
          <Alert status='info'>
            <AlertIcon />
            Please ensure the number is formatted as "18760000000"
          </Alert>
          <Spacer h="20px" />
          <Input type="file" accept=".csv" p={1} onChange={handleFileChange} />
          <Spacer h="20px" />
          <Grid templateColumns="repeat(2, 1fr)" gap={2}>
            <Button onClick={() => handleSendTextMessage(selectedTemplateId, selectedDataId)}
                    isDisabled={selectedTemplateId === '' || selectedDataId === ''}>
              Send Text Message</Button>
            <Button onClick={() => handleBulkSendTextMessages(selectedTemplateId, csvFile)}
                    isDisabled={selectedTemplateId === '' || csvData === ''}>Bulk Send Text Messages</Button>
          </Grid>
        </Container>
      </Center>
    </ChakraProvider>
  );
}

export default App;

import axios from 'axios';

class ExternalApiService {
  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });
  }

  setBearerToken(token) {
    this.api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  async fetchTemplates() {
    return this.api.get('/templates').then(res => res.data);
  }

  async fetchTemplateById(id) {
    return this.api.get(`/templates/${id}`).then(res => res.data);
  }

  async createTemplate(data) {
    return this.api.post('/templates', data).then(res => res.data);
  }

  async updateTemplate(id, data) {
    return this.api.put(`/templates/${id}`, data).then(res => res.data);
  }

  async deleteTemplate(id) {
    return this.api.delete(`/templates/${id}`).then(res => res.data);
  }

  async fetchMessages() {
    return this.api.get('/messages').then(res => res.data);
  }

  async fetchMessageById(id) {
    return this.api.get(`/messages/${id}`).then(res => res.data);
  }

  async createMessage(data) {
    return this.api.post('/messages', data).then(res => res.data);
  }

  async updateMessage(id, data) {
    return this.api.put(`/messages/${id}`, data).then(res => res.data);
  }

  async deleteMessage(id) {
    return this.api.delete(`/messages/${id}`).then(res => res.data);
  }

  async sendMessage(id) {
    return this.api.post('/messages/send', { id }).then(res => res.data);
  }

  async sendBulkMessages(templateId, csvFile) {
    const formData = new FormData();
    formData.append('template_id', templateId);
    formData.append('csv_file', csvFile);
    return this.api.post('/messages/send-bulk', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(res => res.data);
  }

  async previewTemplate(selectedTemplateId, currentData) {
    return this.api.post(`/templates/${selectedTemplateId}/preview`, currentData).then(res => res.data);
  }

  async fetchUsages() {
    return this.api.get('/usages').then(res => res.data);
  }
}

export const apiService = new ExternalApiService();
